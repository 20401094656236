<template>
    <div class="body indexFlex">
        <van-swipe class="my-swipe" :autoplay="3000" :height="200" indicator-color="white">
            <van-swipe-item v-for="(item, index) in images" :key="index" class="swipe-img">
                <img :src="item"/>
            </van-swipe-item> 
        </van-swipe>
        <div class="indexFlex1">
            <van-grid :column-num="3">
                <van-grid-item v-for="(item,index) in routers" :key="index" :icon="item.icon" :text="item.name" 
                :info="(item.name=='消息记录'&&unreadNum>0)?unreadNum:''"
                :to="item.url" @click="pfdClick(item)">
                </van-grid-item>
            </van-grid>
        </div>
        <div v-if="isCorpWX" class="kefu" @click="tokefu"><img src="../../assets/icon/kefu.png" alt=""></div>
        <div class="tip"  v-if="!isCorpWX">
            体验报表统计等更多功能，尽在PC端<br>
            网页用户访问http://worktime.ttkuaiban.com，钉钉用户直接从钉钉PC端进入工时管家
        </div>
        <Footer ref="child" :key="key"></Footer>
    </div>
</template>
<script>
    import Footer from "@/components/Footer";
    import Vue from "vue";
    import { ImagePreview } from "vant";
    Vue.use(ImagePreview);
    export default {
        data() {
            return {
                yuzhongCompId: 3385,
                isCorpWX:false,
                isWX:false,
                user: null,
                unreadNum:0,
                images: [
                    // require('../../assets/img/index/banner_1.png'),
                    require('../../assets/img/index/banner4.jpeg'),
                    // require('../../assets/img/index/banner_3.png'),
                ],
                routers: [],
                key: 0,
                isSyncData: false,
                jobNumberCheckCompanyId: [936], // 定制需求，需要完善工号的公司id
            };
        },
        created() {
            if(localStorage.userInfo) {
                this.user = JSON.parse(localStorage.userInfo)
                // 是否为钉钉同步
                this.isSyncData = this.user.timeType.syncDingding || this.user.timeType.syncFanwei;
            } 
        },
        mounted() {
            //有错误信息，优先跳转到登录页面去
            if(window.location.href.indexOf('errorMsg') != '-1') {
                this.$router.push("/login");
            }
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            let that = this;
            //企业微信或个人微信是从后台授权后跳转过来的
            if (this.isCorpWX || this.isWX) {
                //后台自动授权登录的用户
                if(window.location.href.indexOf('userId') != '-1') {
                    let href = window.location.href;
                    var loginUserId = href.substring(href.indexOf("userId=")+"userId=".length);
                    if (loginUserId.includes('#/')) {
                        loginUserId = loginUserId.substring(0, loginUserId.indexOf('#/'));
                    }
                    that.getAccountInfo(loginUserId)
                } else {
                    //存在一部分用户，没有绑定企业微信，进来后跳到登录页面，登录页面输入账号密码进来后不带userId
                    if (this.user) {
                        that.getAccountInfo(that.user.id);
                    } else {
                        that.$router.push("/login");
                    }
                }
            } else {
                //其他情况，刷新用户信息
                if (localStorage.userInfo != null && localStorage.userInfo != 'undefined') {
                    that.user = JSON.parse(localStorage.userInfo);
                    that.getAccountInfo(that.user.id);
                } else{
                    //无用户信息，跳到登录页面去
                    this.$router.push("/login");
                }
            }
            if (this.user) {
                let userss = this.user;
                if(userss.isMobFirstLogin == 1 && userss.createTime[0] > '2022' && userss.roleName == '超级管理员') {
                    // 第一次登陆显示
                    this.previewPicture()
                }
            }

            // 检查是否有工号
            const { companyId, jobNumber, userNameNeedTranslate, name, dingdingUserid } = this.user
            if(this.jobNumberCheckCompanyId.includes(companyId) && !jobNumber) {
                this.$router.push({
                    path: '/editPerfect',
                    query: {
                        showjobNumber: 'true'
                    }
                });
            }

            // 检查是否需要完成姓名
            if(userNameNeedTranslate == 0 && (name == dingdingUserid)) {
                this.$router.push({
                    path: '/editPerfect',
                    query: {
                        showjobNumber: 'false'
                    }
                });
            }

            if(this.user.dingdingUserid) {
                this.setDDOpenData()
            }
        },
        components: {
            Footer
        },
        methods: {
            setDDOpenData() {
                const ddUrl = new URL(window.location.href);
                const ddCorpid = ddUrl.searchParams.get("corpid") || ''
                let ddOpenDataInit = window.DTOpenData.init(ddCorpid)
                console.log(ddOpenDataInit, '<=== 钉钉执行 window.DTOpenData.init(ddCorpid) 的判断')
                if (ddOpenDataInit) {
                    setTimeout(() => {
                        console.log('<==== 执行 window.DTOpenData.update(document.querySelectorAll(dt-open-data))')
                        window.DTOpenData.update(document.querySelectorAll('dt-open-data'));
                    }, 300)
                }else {
                    console.log('钉钉没有触发')
                }
            },
            pfdClick(item) {
                if(item.name == '使用说明') {
                    let url = 'http://celiang.oss-cn-hangzhou.aliyuncs.com/measurement/2022-01/18/75it6phpocqYFV1642488558220118.pdf'
                    let name = '使用说明书'
                    // 将要传过去的值
                    this.previewPDF(url, name)
                }
            },
            // 预览pdf
            previewPDF(url, name) {
                this.$router.push({
                    path:  '/pdf',
                    query: {
                        url: '',
                        name: name
                    }
                })
            },
            // 图片预览
            previewPicture() {
                let that = this
                let arr = [
                    'https://mobworktime.ttkuaiban.com/upload/b9cf863819924106a65255ccffae446d.png',
                    'https://mobworktime.ttkuaiban.com/upload/65afcaed7ad84c51a309db69c888fe6c.png',
                    'https://mobworktime.ttkuaiban.com/upload/3452d33a68bb4092b04147e5482fd0e9.png',
                    'https://mobworktime.ttkuaiban.com/upload/af5b430f48b24b2683fb48a43deab98c.png',
                    'https://mobworktime.ttkuaiban.com/upload/b69779d86be44ef3a1d3585816fde5f3.png'
                ]
                ImagePreview({
                    images: arr,
                    startPosition: 0,
                    showIndicators: true,
                    closeable: true,
                    onClose() {
                        that.previewPictureClose()
                    },
                });
            },
            // 处理关闭事件
            previewPictureClose() {
                this.$axios.post("/user/mobSkipGuidance", {
                })
                .then(res => {
                    if(res.code == "ok") {
                       let userss = JSON.parse(localStorage.userInfo)
                       userss.isMobFirstLogin = 0
                       localStorage.userInfo = JSON.stringify(userss);
                    } 
                }).catch(err=> {this.$toast.clear();});
            },
            tokefu(){
                wx.invoke('openThirdAppServiceChat', {
                    }, function(res) {
                        console.log('invoke',res);
                        if (res.err_msg == "openThirdAppServiceChat:ok" || res.err_msg == "openThirdAppServiceChat:cancel") {
                        }else{
                            this.$toast.fail('请联系管理员添加客服');
                        }
                        
                    }
                );
            },
            // 模块
            getModule() {
                if(this.user.userNameNeedTranslate == '1'){
                    this.agentConfig()
                }

                if (this.user.company.packageEngineering == 1) {
                    this.routers.push(
                    {
                        name: '专业审核',
                        url: '/profession_review',
                        icon: 'todo-list-o'
                    });
                }
                var list = this.user.moduleList
                let jurisdictionNameList = this.user.functionList.map(item => item.name)
                for (var i in list) {
                    if(list[i].name == '工时报告') {
                        this.routers.push({name: '查看日报',url: '/calendar',icon: 'description'})
                        if(this.user.timeType.enableNewWeeklyfill != 1) { // 针对新版按周填报去掉填写日报
                            this.routers.push({name: '填写日报',url: '/edit',icon: 'edit'})
                        }
                        if (this.user.companyId != 3092 && this.user.timeType.type != 0) {
                            this.routers.push({name: '按周填报',url: '/weekEdit',icon: 'records'})
                        }
                        if(jurisdictionNameList.some(item => item == '代填日报')) {
                            this.routers.push({name: '代填日报',url: '/edit?substitute=true',icon: 'completed'})
                        }
                    }
                    if(list[i].name == '待办任务') {
                        this.routers.push({
                            name: '待办任务',
                            url: '/task',
                            icon: 'coupon-o'
                        });
                    }
                    if(list[i].name == '项目报告审核') {
                        this.routers.push({name: '项目报告审核',url: '/review',icon: 'todo-list-o'})
                    }
                    if(list[i].name == '项目管理') {
                        this.routers.push({name: '项目管理',url: '/project',icon: 'label-o'})
                    }
                    if(list[i].name == '导入日报审核') {
                        this.routers.push({name: '导入日报审核',url: '/audit',icon: 'completed'})
                    }

                    if(!this.isSyncData) {
                        if(list[i].name == '请假管理') {
                            this.routers.push({name: '请假管理',url: '/applyLeave',icon: 'notes-o'})
                            // this.routers.push({name: '请假审批',url: '/exaLeave',icon: 'completed'})
                            // this.routers.push({name: '出差管理',url: '/awayOffice',icon: 'completed'})
                        }

                        if(list[i].name == '出差管理') {
                            this.routers.push({name: '出差管理',url: '/awayOffice',icon: 'logistics'})
                        }
                    }
                    

                    if(list[i].name == '费用报销') {
                        this.routers.push({
                            name: '费用报销',
                            url: '/expense',
                            icon: 'balance-list-o'
                        });
                    }
                    
                    if(list[i].name == '设备管理') {
                        this.routers.push({
                            name: '设备管理',
                            url: '/useRegistration',
                            icon: 'label-o'
                        }, {
                            name: '设备使用记录',
                            url: '/usageHistory',
                            icon: 'label-o'
                        });
                    }
                }
                if (this.user.manageDeptId != 0 && this.user.company.packageEngineering == 1) {
                    this.routers.push(
                    {
                        name: '部门审核',
                        url: '/department_review',
                        icon: 'todo-list-o'
                    });
                }
                
                this.routers.push({
                                    name: '消息记录',
                                    url: '/msg',
                                    icon: 'todo-list-o',
                                    info: this.unreadNum
                                });
                
                // 针对超级管理员加使用说明
                if(this.user.roleName == '超级管理员') {
                    this.routers.push(
                    {
                        name: '使用说明',
                        // url: '/',
                        icon: 'todo-list-o'
                    });
                }

                // 设备管理
                // if(this.user.companyId == 10) {
                //     this.routers.push({
                //         name: '设备管理',
                //         url: '/useRegistration',
                //         icon: 'label-o'
                //     }, {
                //         name: '设备使用记录',
                //         url: '/usageHistory',
                //         icon: 'label-o'
                //     });
                // }
            },

            // 获取企业微信参数
            agentConfig(){
                let curUrl = window.location.href.split('#')[0]
                this.$axios.post('/wxcorp/getCorpWXConfig',{
                    url: curUrl,
                    token: this.user.id
                }).then(res => {
                    if(res.code == 'ok'){
                        wx.config({
                            beta: true,
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.data.appid, // 必填，公众号的唯一标识 
                            timestamp: res.data.timestamp, // 必填，生成签名的时间戳 
                            nonceStr: res.data.noncestr, // 必填，生成签名的随机串 
                            signature: res.data.sign, // 必填，签名，见附录1 
                            jsApiList: ['chooseImage','previewImage','uploadImage','downloadImage','previewFile','getLocation','agentConfig'] 
                        })
                        let that = this
                        wx.ready(function(){
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                            that.$axios.post('/wxcorp/getCorpWXAgentConfig', {
                                url: curUrl,
                                token: that.user.id
                            }).then(res => {
                                if(res.code == 'ok'){
                                    wx.agentConfig({
                                        corpid: res.data.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                                        agentid: res.data.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                                        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                                        signature: res.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                                        jsApiList: ['selectExternalContact','openThirdAppServiceChat', 'openAppManage'], //必填，传入需要使用的接口名称
                                        success: function (result) {
                                            // console.log(result, '请求微信成功')
                                            // console.log(window, 'window')
                                            //  wx.agentConfig成功回调后，WWOpenData 才会注入到 window 对象上面
                                            window.WWOpenData.bind(document.querySelector('TranslationOpenDataText'))
                                            // that.kefuShow = true
                                        },
                                        fail: function (res) {
                                            // console.log('查看错误信息', res)
                                            if (res.errMsg.indexOf('function not exist') > -1) {
                                                alert('版本过低请升级')
                                            } else {
                                                // alert('agentConfig出错:'+res.errMsg);
                                            }
                                        },
                                    })
                                }
                            }).catch(err => {
                                if (err.errMsg.indexOf('function not exist') > -1) {
                                    alert('版本过低请升级')
                                }else {
                                    // alert(res.errMsg);
                                }
                            })
                        });
                        wx.error(function(res){
                            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                            // alert('wxConfig发生异常:'+JSON.stringify(res));
                            // 企业第一次授权安装进入后会报not in reliable domain的错误，刷新后正常
                            location.reload();
                        });
                    } else {
                        // alert(''+res);
                    }
                }).catch(err => {
                    alert(err);
                })
            },

            //获取账户信息
            getAccountInfo(userId) {
                this.$axios.get('/user/loginByUserId', {params:{userId: userId}})
                .then(res => {
                    // console.log(res);
                    if (res.code == 'error') {
                        localStorage.errorMsg = res.msg;
                        //账号信息有问题，不能使用。
                        this.$router.push("/error");
                    } else {
                        localStorage.userInfo = JSON.stringify(res.data);
                        this.user = res.data;
                        this.$refs.child.dealWith()
                        this.key++
                        this.getModule()
                        this.getMessage();
                        this.redirectIfNess();
                        this.$forceUpdate()
                        if (this.user.companyId == this.yuzhongCompId) {
                            //去掉按周填报
                            this.routers = this.routers.filter(r=>r.name != '按周填报');
                        } 
                        // this.bindIfNessary();
                    } 
                }).catch(err=> {
                    alert('err=' + err);
                });
            },
            redirectIfNess() {
                var href = window.location.href;
                if (href.indexOf('path') > 0) {
                    var path = href.split('path=')[1].split('&')[0];
                    if (path == 'expense' || path == 'task') {
                        this.$router.push(path);
                    }
                }
            },
            bindIfNessary() {
                let href = window.location.href;
                var requestUrl = "";
                if (this.isCorpWX && (this.user.corpwxUserid == null|| this.user.corpwxUserid == undefined || this.user.corpwxUserid == 'undefined')) {//优先检查企业微信环境
                    requestUrl = "/wxcorp/bindCorpWeiXin";
                } else if (this.isWX && (this.user.wxOpenid == null || this.user.wxOpenid == undefined || this.user.wxOpenid == 'undefined')) {
                    requestUrl = "/wechat/bindWeiXin";
                } 
                
                if (requestUrl.length > 0) {
                    // localStorage.openId = 'o1L3L5lOrOl3_UEJjONaoT2Rne1I';
                    //会自动跳转到首页
                    // let href = 'http://hq.tangusoft.com/?code=011Ptjgc2rx1eI09Irgc2Rvsgc2PtjgF&state=1#/index';
                    
                    if (href.includes("com/?code")) {  //url包括 com/?code 证明为从微信跳转回来的
                        var url = href; //vue自动在末尾加了 #/ 符号，截取去掉
                        var jingPosit = url.indexOf("com/") + 4; //获取域名结束的位置

                        // var urlLeft = url.substring(0, jingPosit);//url左侧部分
                        var urlRight = url.substring(jingPosit, url.length); //url右侧部分
                        // console.log('urlRight=' + urlRight);
                        // window.location = urlLeft + "#/home" + urlRight;//拼接跳转
                        //获取code
                        var code = urlRight.substring('?code='.length,urlRight.indexOf('#/index'));
                        if (code.indexOf('&state=1') > 0) {
                            code = code.substring(0, code.indexOf('&state=1'));
                        }

                        //调用后台接口，注册用户
                        // console.log('获取到code=='+code);

                        this.$axios.get(requestUrl, {params:{code:code, userId: this.user.id}})
                            .then(res => {
                                // console.log(res);
                                if (res == null) {
                                    this.$toast.fail('绑定失败');
                                } else if(res.errcode != null) {
                                    //报错了
                                    console.log(res.errmsg);
                                } else {
                                    //获取openId
                                    if (res.data != null && ((this.isWX && res.data.wxOpenid != undefined)
                                                || (this.isCorpWX && res.data.corpwxUserid != undefined))) {
                                        localStorage.userInfo = JSON.stringify(res.data);
                                        // console.log('绑定成功');
                                        this.user = res.data;
                                        window.location.href = '/#/my/center';
                                    }
                                }
                            }).catch(err=> {
                                alert('err=' + err);
                            });
                    }
                }
            },

            //获取消息
            getMessage() {
                this.$axios.post("/information/list", {
                })
                .then(res => {
                    if(res.code == "ok") {
                        var list = res.data;
                        this.unreadNum = list.filter(l=>l.checked==0).length;
                        // console.log(this.unreadNum);
                        
                    } 
                }).catch(err=> {this.$toast.clear();});
            },

        },
        activated(){
            this.getMessage()
        },
        
    };
</script>

<style lang="less" scoped>
    .indexFlex {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .indexFlex1 {
            flex: 1;
            overflow-y: auto;
        }
    }
    .swipe-img {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .body {
        height: calc(100vh - 50px);
        position: relative;
    }
    .kefu{
        position: fixed;
        right: 35px;
        bottom: 120px;
        width: 36px;
        height: 36px;
        background-color: #fff;
        z-index: 1000;
        border-radius: 50%;
        img{
            margin: 4px;
            width: 28px;
            height: 28px;
        }
    }

    .tip {
        width: 100%;
        bottom: 5px;
        font-size: 14px;
        color: #8f8f8f;
        margin-top: 10px;
        text-align: center;
        line-height: 20px;
    }
</style>
